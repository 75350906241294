
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _404jDoNTuM4JPMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/404.vue?macro=true";
import { default as budgetHxYKGWJWzeMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/budget.vue?macro=true";
import { default as expiredycbznWTgAkMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/expired.vue?macro=true";
import { default as processedFHHLStzLd7Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/processed.vue?macro=true";
import { default as indextVKMHpZDS0Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/analytics/pages/analytics/index.vue?macro=true";
import { default as _91id_93SCAk8tuWcHMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/[product]/[briefing]/[id].vue?macro=true";
import { default as createwqB0FDADWBMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/create.vue?macro=true";
import { default as index0WWlPnSrfAMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/index.vue?macro=true";
import { default as _91_46_46_46slug_93QtDuVko6K2Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/[...slug].vue?macro=true";
import { default as createuJO6JrtYJjMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/create.vue?macro=true";
import { default as indexnTX3miezuvMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/index.vue?macro=true";
import { default as _91id_930wA4FwT5vCMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/[customer]/[id].vue?macro=true";
import { default as createcbK5pPEI4iMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/create.vue?macro=true";
import { default as index3BB8mglY9KMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/index.vue?macro=true";
import { default as create0wrpQYf0M9Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/create.vue?macro=true";
import { default as indexRauAMbf8a0Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/index.vue?macro=true";
import { default as createGDMCfXxNWDMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/create.vue?macro=true";
import { default as index2ER8sYEmWEMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/index.vue?macro=true";
import { default as _91_46_46_46slug_93LvxidFjOUzMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/[...slug].vue?macro=true";
import { default as createe766ZrLsktMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/create.vue?macro=true";
import { default as indexoNHGcdfsKPMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/index.vue?macro=true";
import { default as _91_46_46_46slug_937d0xG1JovRMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/[...slug].vue?macro=true";
import { default as createt66id7ZaBqMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/create.vue?macro=true";
import { default as indexCVpk5KO3p3Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/index.vue?macro=true";
import { default as _91_46_46_46slug_93hK0zOn7inNMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/[...slug].vue?macro=true";
import { default as createv1sbpp3RobMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/create.vue?macro=true";
import { default as indexLLXcvoWIF2Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/index.vue?macro=true";
import { default as _91_46_46_46slug_93RxPtRXvYU9Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/[...slug].vue?macro=true";
import { default as createXke27MyoRGMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/create.vue?macro=true";
import { default as indexNc9mgDzKA3Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/index.vue?macro=true";
import { default as indexR8Wpr7Cfn6Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93buhu8lL50qMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/[...slug].vue?macro=true";
import { default as indexWnQA2Gos3bMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/activate/index.vue?macro=true";
import { default as createBA3IwzndOOMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/create.vue?macro=true";
import { default as index162zJk91Z9Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/index.vue?macro=true";
import { default as login0kc5iuWuglMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/login.vue?macro=true";
import { default as meta_45tests6eTidsUSLpMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/meta-tests.vue?macro=true";
import { default as _91_46_46_46slug_934O1vwzTc8oMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/[...slug].vue?macro=true";
import { default as createvabIRr5mRnMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/create.vue?macro=true";
import { default as indexFprNbnsdvQMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/index.vue?macro=true";
import { default as _91_46_46_46slug_93TaG4PE8F1wMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/[...slug].vue?macro=true";
import { default as createkJ7pijJJZvMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/create.vue?macro=true";
import { default as indexzDRHFRtMP3Meta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/index.vue?macro=true";
import { default as privacyLpzYKxHrppMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/privacy.vue?macro=true";
import { default as _91_46_46_46slug_93138plgn6tPMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/[...slug].vue?macro=true";
import { default as indexDfgjFddnQeMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/index.vue?macro=true";
import { default as remember_45automaticX0ak6Nfz0bMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/remember-automatic.vue?macro=true";
import { default as remember6NKAxQMJDOMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/remember.vue?macro=true";
import { default as indexBwiM9hSZ7hMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/saas-clientes/index.vue?macro=true";
import { default as termsIrmBH2wlioMeta } from "/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/terms.vue?macro=true";
export default [
  {
    name: "404___es",
    path: "/404",
    meta: _404jDoNTuM4JPMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/404.vue")
  },
  {
    name: "adify-budget___es",
    path: "/adify/budget",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/budget.vue")
  },
  {
    name: "adify-expired___es",
    path: "/adify/expired",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/expired.vue")
  },
  {
    name: "adify-processed___es",
    path: "/adify/processed",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/adify/pages/adify/processed.vue")
  },
  {
    name: "analytics___es",
    path: "/analytics",
    meta: indextVKMHpZDS0Meta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/analytics/pages/analytics/index.vue")
  },
  {
    name: "briefings-product-briefing-id___es",
    path: "/briefings/:product()/:briefing()/:id()",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/[product]/[briefing]/[id].vue")
  },
  {
    name: "briefings-create___es",
    path: "/briefings/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/create.vue")
  },
  {
    name: "briefings___es",
    path: "/briefings",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/briefings/index.vue")
  },
  {
    name: "config-companies-slug___es",
    path: "/config/companies/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/[...slug].vue")
  },
  {
    name: "config-companies-create___es",
    path: "/config/companies/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/create.vue")
  },
  {
    name: "config-companies___es",
    path: "/config/companies",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/companies/index.vue")
  },
  {
    name: "config-customers-customer-id___es",
    path: "/config/customers/:customer()/:id()",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/[customer]/[id].vue")
  },
  {
    name: "config-customers-create___es",
    path: "/config/customers/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/create.vue")
  },
  {
    name: "config-customers___es",
    path: "/config/customers",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/customers/pages/config/customers/index.vue")
  },
  {
    name: "config-documentation-create___es",
    path: "/config/documentation/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/create.vue")
  },
  {
    name: "config-documentation___es",
    path: "/config/documentation",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/documentation/index.vue")
  },
  {
    name: "config-invoices-create___es",
    path: "/config/invoices/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/create.vue")
  },
  {
    name: "config-invoices___es",
    path: "/config/invoices",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/invoices/index.vue")
  },
  {
    name: "config-plans-slug___es",
    path: "/config/plans/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/[...slug].vue")
  },
  {
    name: "config-plans-create___es",
    path: "/config/plans/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/create.vue")
  },
  {
    name: "config-plans___es",
    path: "/config/plans",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/plans/index.vue")
  },
  {
    name: "config-products-slug___es",
    path: "/config/products/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/[...slug].vue")
  },
  {
    name: "config-products-create___es",
    path: "/config/products/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/create.vue")
  },
  {
    name: "config-products___es",
    path: "/config/products",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/products/index.vue")
  },
  {
    name: "config-signups-slug___es",
    path: "/config/signups/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/[...slug].vue")
  },
  {
    name: "config-signups-create___es",
    path: "/config/signups/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/create.vue")
  },
  {
    name: "config-signups___es",
    path: "/config/signups",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/signups/index.vue")
  },
  {
    name: "config-users-slug___es",
    path: "/config/users/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/[...slug].vue")
  },
  {
    name: "config-users-create___es",
    path: "/config/users/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/create.vue")
  },
  {
    name: "config-users___es",
    path: "/config/users",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/settings/pages/config/users/index.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/index.vue")
  },
  {
    name: "insights-slug___es",
    path: "/insights/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/[...slug].vue")
  },
  {
    name: "insights-activate___es",
    path: "/insights/activate",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/activate/index.vue")
  },
  {
    name: "insights-create___es",
    path: "/insights/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/create.vue")
  },
  {
    name: "insights___es",
    path: "/insights",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/insights/pages/insights/index.vue")
  },
  {
    name: "login___es",
    path: "/login",
    meta: login0kc5iuWuglMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/login.vue")
  },
  {
    name: "meta-tests___es",
    path: "/meta-tests",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/meta-tests.vue")
  },
  {
    name: "parameters-slug___es",
    path: "/parameters/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/[...slug].vue")
  },
  {
    name: "parameters-create___es",
    path: "/parameters/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/create.vue")
  },
  {
    name: "parameters___es",
    path: "/parameters",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/parameters/pages/parameters/index.vue")
  },
  {
    name: "plannings-slug___es",
    path: "/plannings/:slug(.*)*",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/[...slug].vue")
  },
  {
    name: "plannings-create___es",
    path: "/plannings/create",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/create.vue")
  },
  {
    name: "plannings___es",
    path: "/plannings",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/plannings/index.vue")
  },
  {
    name: "privacy___es",
    path: "/privacy",
    meta: privacyLpzYKxHrppMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/privacy.vue")
  },
  {
    name: "Visualización___es",
    path: "/products/:slug(.*)*",
    meta: _91_46_46_46slug_93138plgn6tPMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/[...slug].vue")
  },
  {
    name: "Productos___es",
    path: "/products",
    meta: indexDfgjFddnQeMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/briefings/pages/products/index.vue")
  },
  {
    name: "remember-automatic___es",
    path: "/remember-automatic",
    meta: remember_45automaticX0ak6Nfz0bMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/remember-automatic.vue")
  },
  {
    name: "remember___es",
    path: "/remember",
    meta: remember6NKAxQMJDOMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/remember.vue")
  },
  {
    name: "saas-clientes___es",
    path: "/saas-clientes",
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/app/saas/pages/saas-clientes/index.vue")
  },
  {
    name: "terms___es",
    path: "/terms",
    meta: termsIrmBH2wlioMeta || {},
    component: () => import("/Users/adria-fsbd/Documents/FSBD-Projects/layers/core/pages/terms.vue")
  }
]